<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 30.3 29.6" style="enable-background:new 0 0 30.3 29.6;" xml:space="preserve">
    <path id="git" class="st0" d="M15.2,0.5C7.1,0.5,0.5,7.1,0.5,15.2c0,6.5,4.2,12,10,13.9c0.7,0.1,1-0.3,1-0.7c0-0.3,0-1.3,0-2.5
        c-4.1,0.9-4.9-2-4.9-2c-0.7-1.7-1.6-2.1-1.6-2.1C3.6,20.9,5,20.9,5,20.9c1.5,0.1,2.2,1.5,2.2,1.5c1.3,2.2,3.4,1.6,4.3,1.2
        c0.1-0.9,0.5-1.6,0.9-2c-3.3-0.4-6.7-1.6-6.7-7.2c0-1.6,0.6-2.9,1.5-3.9c-0.2-0.4-0.7-1.9,0.1-3.9c0,0,1.2-0.4,4,1.5
        c1.2-0.3,2.4-0.5,3.7-0.5c1.2,0,2.5,0.2,3.7,0.5c2.8-1.9,4-1.5,4-1.5c0.8,2,0.3,3.5,0.1,3.9c0.9,1,1.5,2.3,1.5,3.9
        c0,5.6-3.4,6.9-6.7,7.2c0.5,0.5,1,1.3,1,2.7c0,2,0,3.5,0,4c0,0.4,0.3,0.8,1,0.7c5.8-1.9,10-7.4,10-13.9C29.8,7.1,23.3,0.5,15.2,0.5"
        />
    </svg>  
</template>
<script>
export default {
  name: 'Git'
}
</script>
<style lang="scss">
  .git{
    margin-top: -2px;
    margin-left: 8px;
    width: 29px;

    svg {
        width: 100%;
   
        .st0{
          fill: #4580aa;
          transition: .3s;
        }
    }
  }
</style>
