<template>
  <div class="linkedin">  
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 144.5 144" style="enable-background:new 0 0 144.5 144;" xml:space="preserve">
        <g>
            <g>
                <g>
                    <path class="st0" d="M133.3,0H10.6C4.8,0,0,4.6,0,10.4v123.2c0,5.7,4.8,10.4,10.6,10.4h122.7c5.9,0,10.7-4.7,10.7-10.4V10.4
                        C144,4.6,139.2,0,133.3,0z M42.7,122.7H21.3V54h21.4V122.7z M32,44.6c-6.8,0-12.4-5.5-12.4-12.4c0-6.8,5.5-12.4,12.4-12.4
                        c6.8,0,12.4,5.5,12.4,12.4C44.4,39,38.9,44.6,32,44.6z M122.7,122.7h-21.3V89.3c0-8-0.1-18.2-11.1-18.2
                        c-11.1,0-12.8,8.7-12.8,17.6v34H56.1V54h20.5v9.4h0.3c2.9-5.4,9.8-11.1,20.2-11.1c21.6,0,25.6,14.2,25.6,32.7V122.7z"/>
                </g>
            </g>
        </g>
    </svg>
  </div>
</template>
<script>
export default {
  name: 'Linkedin'
}
</script>
<style lang="scss">
  .linkedin{
    margin-left: 8px;
    width: 26px;

    svg {
        width: 100%;
   
        .st0{
          fill: #4580aa;
          transition: .3s;
        }
    }
    path.line {
      fill: #4580aa;
    }
  }
</style>
